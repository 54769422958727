import React, { useState } from "react"
import * as styles from "../../pages/seller/sellerProp.module.css"
import "react-multi-carousel/lib/styles.css"
import "bootstrap/dist/css/bootstrap.css"
import closebutton from "../../../static/images/deleteIcon.png"
import Group236 from "../../../static/images/sharing.png"
import memberIcon from "../../../static/images/memberIcon.png"
import * as style1 from '../../pages/dashboard/detail.module.css';
import SharePopup from "../popup/sharePopup"
import axios from 'axios'
import Dummy from "../../../static/images/house4.png"
import PropMap from "./PropMap3"
import newBrokaragelogo from "../../../static/images/newBrokaragelogo.png"
import coldwell from "../../../static/images/coldwell.png"
import intero from "../../../static/images/intero.png"
import kellerwilliams from "../../../static/images/kellerwilliams.png"
import compass from "../../../static/images/compass.png"
import theagency from "../../../static/images/theagency.png"

function HouseDetails(props) {
  const { lat, lng } = props
  const close = () => {
    props.close()
  }
  const [googleImg, setGoogleImg] = useState("")

  const hanldeImage = img => {
    let data = img !== null && img.split("/")[0]
    if (data === "uploads") {
      return process.env.API_IMAGE_URL + img
    } else {
      return img
    }
  }
  const [shows, setShows] = useState(false)
  const toggleShows = () => setShows(p => !p)
  // image issue //
  const ImageCard = (address) => {
    if (address !== undefined) {
      var address1 = address.replace("#", "")
      axios
        .get(`https://maps.googleapis.com/maps/api/streetview?size=449x290&location=${address1}&key=AIzaSyCVpTy7dON49XuSmtftSLfjgw2QxTSia2M`, {
          headers: {
            "Content-Type": 'application/json;charset=UTF-8'
          }
        })
        .then((response) => {
          setGoogleImg(`https://maps.googleapis.com/maps/api/streetview?size=449x290&location=${address1}&key=AIzaSyCVpTy7dON49XuSmtftSLfjgw2QxTSia2M`)
        })
        .catch((error) => {
          setGoogleImg(Dummy)
        });
    }
    return (
      googleImg
    )
  }

  return (
    <div className={styles.ImageView1}>
      <SharePopup shows={shows} toggleShows={toggleShows} id={props.image !== undefined && props.image._id} />
      <>
        {props.image.thumbnail_image !== undefined && props.image.thumbnail_image !== null && props.image.thumbnail_image !== "" ?
          <>
            {props.image !== null && props.image.thumbnail_image !== undefined && props.image.thumbnail_image.includes('mls') ?
              <img src={hanldeImage(props.image !== null && props.image.thumbnail_image)} alt="mask192" className={styles.AvatarImageOwned} style={{ width: "100%", padding: "10px 0", height: "304px", position: "relative", top: "6px", objectFit: "contain" }} /> :
              <img src={hanldeImage(props.image !== null && props.image.thumbnail_image)} alt="mask192" className={styles.AvatarImageOwned} style={{ width: "100%", padding: "10px 0", height: "304px", position: "relative", top: "6px", objectFit: "contain" }} crossOrigin="anonymous" />}

          </> :
          <div>
            {props.image.location ?
              <img src={`https://maps.googleapis.com/maps/api/streetview?size=449x290&location=${props.image.address.replaceAll("#", "")}&key=AIzaSyCVpTy7dON49XuSmtftSLfjgw2QxTSia2M`} alt="EditGroup10 " className={styles.AvatarImageOwned} style={{ width: "100%", padding: "10px 0", height: "304px", position: "relative", top: "6px", objectFit: "contain" }} />
              :
              <>{props.image.property_latitude !== null && props.image.property_longitude !== null &&
                <PropMap lat={parseFloat(props.image.property_latitude)} lng={parseFloat(props.image.property_longitude)} />
              }</>}
          </div>}
      </>
      {props.image !== undefined && (props.image.listing_status === 3 || props.image.listing_status === "3") &&
        <div>
          <div> <img src={props.image.companyID === "1" ? coldwell : props.image.companyID === "2" ? kellerwilliams : props.image.companyID === "3" ? compass : props.image.companyID === "4" ? intero : props.image.companyID === "5" ? theagency : ""} alt="memberIcon" className={style1.memberIcon} /> Exclusive</div>
        </div>
      }
      {(props?.image?.thumbnail_image == null || props?.image?.thumbnail_image == undefined) && props?.image?.location?.coordinates[0] == undefined &&
        <img className={styles.AvatarImageOwned} src={ImageCard(props.image.address)} alt="EditGroup11 " aria-hidden crossOrigin='anonymous' style={{ width: "100%", padding: "10px 0", height: "448px", position: "relative", top: "6px" }} />}
      <img
        src={closebutton}
        alt="closebutton"
        style={{ position: "absolute", right: '61px', top: "62px", cursor: "pointer" }}
        className={styles.CloseButton}
        onClick={close}
        aria-hidden
      />
      <div className="shareButton" >
        <img src={Group236} alt="group236" className="shareIcon" onClick={toggleShows} aria-hidden />
      </div>
    </div>
  )
}
export default HouseDetails