import React from "react"
import GoogleMapReact from "google-map-react"

const AnyReactComponent = ({ text }) => <div className="mapView"><i className="fa fa-map-marker f-24 color-red" aria-hidden="true"></i>
</div>

function PropMap(props) {
  const data={
    lat: props.lat !== undefined && props.lat !== null && props.lat,
    lng: props.lng !== undefined && props.lng !== null && props.lng
  }
  // console.log("data lat",data)

    return (
      <div style={{ display: "flex" }}>
        <div style={{ width: "95%", height:(props.valueFor=="seller"? "170px":"142px"), overflow: "visible",padding:"5px" }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.FIREBASE_API_KEY,
              libraries:['places'],
            }}
            center={data}
            defaultZoom={15}
          >
            <AnyReactComponent
              lat={data.lat}
              lng={data.lng}
              text="My Marker"
            />
          </GoogleMapReact>
        </div>
      </div>
    )
}

export default PropMap
